
require('./date-repeater');
require('./request-trainee-form');
require('./add-trainee-form');
require('./authorisations');
require('./custom-registration');

$(() => {
  $('.clickable-row').on('click', function(e) {
    e.stopPropagation();
    if ($(this).data('target')) {
      window.open($(this).data('href'), $(this).data('target'));
      return;
    }
    window.location = $(this).data('href');
  });

  const form = $('#attestationClientForm');

  $('#attestationClientFormSubmit').on('click', function(ev) {
    ev.preventDefault();
    const datarange = $('#filter-daterange').val();
    const training_ids = $('#training_ids').val();

    if (!training_ids.length) {
      form.removeAttr('target');
    }
    
    form.find('input[name="daterange"]').val(datarange);
    form.find('input[name="training_ids"]').val(training_ids);
    form.trigger('submit');
  });

  const table = $('#attestationClientTable');

  $('#attestationClient_selectNone').on('click', function() {
    $('td input:checkbox',table).prop('checked', false);
  });

  $('#attestationClient_selectAll').on('click', function() {
    $('td input:checkbox',table).prop('checked', true);
  });
});
